import { useAuthStore } from "~/stores/useAuthStore";
import {USER} from "~/const/USERS";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore(); // make authenticated state reactive
  const { isAuthenticated, isAdminUser } = authStore;

  if (toValue(isAuthenticated) && to?.name === 'login') {
    return navigateTo('/');
  }

  if (toValue(isAuthenticated)
    && to?.name === 'page-for-gray-cardinal-muha'
    && !isAdminUser) {
    return navigateTo('/');
  }

  if (!toValue(isAuthenticated) && to?.name !== 'login') {
    abortNavigation();
    return navigateTo('/login');
  }
});
